import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import UnitList from './components/UnitList';

import * as routes from './units_apiRoutes';
import withSpinner from '../Common/utils/withSpinner';
import client from '../Common/utils/client';
import withAuth from '../Common/utils/withAuth';

const UnitListWithSpinner = withSpinner(UnitList);

class UnitsPage extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      community: {},
      units: []
    };
  }

  componentDidMount() {
    const communityId = this.props.match.params.communityId;
    this.setState({ loading: true });
    client
      .get(routes.ADDRESSCODES(communityId))
      .then(res => {
        let sorted = res.data.results.sort((a, b) =>
          a.unit > b.unit ? 1 : -1
        );
        this.setState({
          units: sorted,
          community: res.data.community,
          loading: false
        });
      })
      .catch(error => {
        Sentry.captureException(error);
        if (error.response?.status === 401) {
          this.props.history.push('/login');
        } else {
          this.props.history.push('/');
        }
      });
  }
  render() {
    return (
      <UnitListWithSpinner
        isLoading={this.state.loading}
        units={this.state.units}
        community={this.state.community}
        {...this.props}
      />
    );
  }
}

export default withAuth(UnitsPage);
