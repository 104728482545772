import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Switch, Redirect } from 'react-router-dom';

import './Common/stylesheets/global.scss';
import ErrorBoundary from './Common/Loading/ErrorBoundary';
import CommunitiesPage from './Communities/CommunitiesPage';
import UnitsPage from './Units/UnitsPage';
import LoginPage from './Login/LoginPage';
import AccessCodePage from './AccessCode/AccessCodePage';

import * as serviceWorker from './Common/utils/serviceWorker';
import * as Sentry from '@sentry/browser';
import viewportHack from './Common/scripts/viewportHack';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE_SHA,
    environment: process.env.REACT_APP_ENVIRONMENT
  });
  Sentry.configureScope(scope => {
    scope.setTag('dwelo_api_host', process.env.REACT_APP_API_HOST);
  });
}

ReactDOM.render(
  <Router>
    <div id="app" className="container-fluid">
      <div className="row full-height">
        <div id="central-column" className="col-centered">
          <ErrorBoundary>
            <Switch>
              <Route exact path="/" component={CommunitiesPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route
                exact
                path="/community/:communityId"
                component={UnitsPage}
              />
              <Route
                exact
                path="/community/:communityId/unit/:unitId/access"
                component={AccessCodePage}
              />
              <Redirect to="/" />
            </Switch>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  </Router>,
  document.getElementById('root'),
  function() {
    // viewport hack for mobile
    viewportHack();
  }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
