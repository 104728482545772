import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import AccessCode from './components/AccessCode';

import * as routes from './accesscode_apiRoutes';
import withSpinner from '../Common/utils/withSpinner';
import client from '../Common/utils/client';
import withAuth from '../Common/utils/withAuth';

const AccessCodeWithSpinner = withSpinner(AccessCode);

class AccessCodePage extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      unit: { residents: [] },
      code: {},
      pins_exist: true
    };
  }

  componentDidMount() {
    const communityId = this.props.match.params.communityId;
    const unitId = this.props.match.params.unitId;
    this.setState({ loading: true });
    client
      .get(routes.ADDRESS(unitId))
      .then(res => {
        let data = res.data.results;
        if (
          res.data.results.length === 0 ||
          Number(data[0].communityId) !== Number(communityId)
        ) {
          this.props.history.push('/');
          return Promise.reject();
        } else {
          this.setState({
            unit: data[0]
          });
          return client.get(routes.ACCESSCODE(data[0].gatewayId));
        }
      })
      .then(res => {
        let data = res.data.results;
        if (data === 'No more pins left') {
          this.setState({ loading: false, pins_exist: false });
        } else if (data.length < 1) {
          this.setState({ loading: false });
        } else {
          this.setState({
            code: data[0],
            loading: false
          });
        }
      })
      .catch(error => {
        Sentry.captureException(error);
        if (error.response?.status === 401) {
          this.props.history.push('/login');
        } else {
          this.props.history.push('/');
        }
      });
  }
  render() {
    return (
      <AccessCodeWithSpinner
        isLoading={this.state.loading}
        code={this.state.code}
        unit={this.state.unit}
        pins_exist={this.state.pins_exist}
        {...this.props}
      />
    );
  }
}

export default withAuth(AccessCodePage);
